export const sendToClarity = () => {
  const userInfo = localStorage.getItem("yp_user") || localStorage.getItem("userInfo");

  if (!userInfo) {
    console.error("[sendToClarity] No user information found in localStorage.");
    return;
  }

  let userObject;
  try {
    userObject = JSON.parse(userInfo);
  } catch (error) {
    console.error("[sendToClarity] Error parsing userInfo from localStorage:", error);
    return;
  }

  const referenceId = userObject?.reference_id;
  if (!referenceId) {
    console.error("[sendToClarity] User reference not found.");
    return;
  }

  if (typeof window.clarity !== "function") {
    console.error("[sendToClarity] Clarity is not available on the window object.");
    return;
  }

  window.clarity("set", "user_reference_id", referenceId);
  console.info("[sendToClarity] Data sent to Clarity:", { user_reference_id: referenceId });
};
