import Button from "../button";
import { Container } from "./styles";
import CloseIconBlack from "../../assets/icons/close-icon-black.svg";

export default function GenericModalBeforeReward({
  isOpen,
  clickContinue,
  handleClose,
  color,
  product
}) {
  const title = product?.modal_before_reward_title || "";
  const content = product?.modal_before_reward_body || "";

  return (
    <Container data-show={isOpen} cor={color}>
      <div className="content genericModal">
        <button 
          className="close-modal" 
          onClick={() => handleClose(false)}
          aria-label="Fechar modal"
        >
          <img src={CloseIconBlack} alt="" width={20} height={20} />
        </button>

        <div className="titulo">
          <h2>{title}</h2>
        </div>

        <div className="mensagem">
          {typeof content === 'string' ? (
            <span>{content}</span>
          ) : (
            content
          )}
        </div>

        <Button
          title="Continuar para resgate"
          tipo="branco"
          onClick={clickContinue}
          fullWidth
        />

        <p className="depois" onClick={() => handleClose(false)}>
          Voltar
        </p>
      </div>
    </Container>
  );
}