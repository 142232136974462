import { useState } from "react";
import Button from "../button";
import CheckBox from "../checkbox";

import { CodigoSms } from "./styles";

import img1 from "../../assets/img/fecha-preto.svg";
import BlackEye from "../../assets/img/BlackEye.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import ClickHandIcon from '../Icons/ClickHandIcon';
import GiftBoxIcon from '../Icons/GiftBoxIcon';

import GenericModalBeforeReward from "./GenericModalBeforeReward";

export default function ModalGrupoLista(props) {

  const [openGenericModalBeforeReward, setOpenGenericModalBeforeReward] = useState(false);
  const [dataGenericModalBeforeReward, setDataGenericModalBeforeReward] = useState(null);

  const {
    isOpen,
    handleClose,
    cor,
    prod,
    handleSaibaMais,
    openResgate,
    configuracao,
    configLoja,
  } = props;

  const [check, setCheck] = useState(0);

  const extractBoldText = (text) => {
    const match = text.match(/#(.*?)#/);
    return match ? match[1] : "";
  };

  const removeBoldMarks = (text) => {
    const cleanedText = text.replace(/#.*?#/, "");
    console.log("Cleaned Text:", cleanedText);
    return cleanedText;
  };

  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop") {
      setCheck(0);
      handleClose();
    }
  }

  function verResgates(resgates, variacao_id) {
    console.log("Resgates", resgates);
    if (resgates === 0) {
    } else {
      navigate("/" + configuracao?.key + "/finalizacao", {
        state: { variacao_id: variacao_id },
      });
    }
  }

  function escolhePremio() {
    prod?.products?.forEach((product) => {
      if (product.id === check) {
        if (product.info.show_modal_before_reward === true) {
          setDataGenericModalBeforeReward(product.info);
          setOpenGenericModalBeforeReward(true);
          handleClose(false);
        } 
        else {
          setCheck(0);
          handleClose(false);
          openResgate(product.info);
        }
      }
    });
  }

  function clickBox(id) {
    if (id === check) {
      setCheck(0);
    } else {
      setCheck(id);
    }
  }

  useEffect(() => {
    console.log("Produtos:", prod?.products);
    const checkButtonVisibility = () => {
      return !prod?.products?.some(
        (product) => product.info?.show_extra_reward_button_on_bottom_item === 1
      );
    };

    if (prod?.products?.length > 0) {
      setShowButton(checkButtonVisibility());
    }
  }, [prod?.products]);

  return (
    <>
    <CodigoSms data-show={isOpen} onClick={handleContainerClick} cor={cor}>
      <div className="content grupoLista">
        <button
          className="close-modal"
          onClick={() => {
            setCheck(0);
            handleClose(false);
          }}
        >
          <img src={img1} alt="" width={20} height={20} />
        </button>

        <div className="titulo">
          <GiftBoxIcon color={configuracao?.background} />
          <h3 style={{ color: configuracao?.background }}>ESCOLHA SEU PRÊMIO | {prod?.name}</h3>
        </div>

        {prod?.products?.map((product) => {
          return (
            <div
              className={`box ${check === product.id ? "active" : ""} ${product?.info?.blocked === 1 ? "box-indisponivel" : ""
                }`}
              key={product.id}
              onClick={() => clickBox(product.id)}
            >
              <img
                className="img-produto"
                src={product?.info?.img_modal}
                alt=""
              />
              <span>{product?.info?.name}</span>
              {product?.info?.blocked === 1 ? (
                <div
                  className="indisponivel"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      backgroundColor: "#d9d9d9",
                      borderRadius: "5px",
                      padding: "6px 8px",
                    }}
                  >
                    {product?.info?.unavaliable_text ? (
                      <p>
                        {removeBoldMarks(product.info.unavaliable_text)}{" "}
                        <strong>
                          {extractBoldText(product.info.unavaliable_text)}
                        </strong>
                      </p>
                    ) : (
                      <>
                        <p>Indisponível</p>
                        <span>Reposição em até 48h</span>
                      </>
                    )}
                  </div>
                  {product?.info?.show_extra_reward_button_on_bottom_item ===
                    1 ? (
                    <div
                      style={{
                        cursor: "pointer",
                        marginTop: "8px",
                        alignItems: "center",
                        height: "20px",
                        display: "flex",
                        borderRadius: "5px",
                        padding: "6px 8px",
                      }}
                      onClick={() =>
                        verResgates(
                          configLoja?.user_reward_info.rewards,
                          product?.info?.variation_id
                        )
                      }
                    >
                      <img
                        style={{
                          height: "10px",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                        src={BlackEye}
                        alt="Olho"
                      />
                      <p>
                        {product?.is_coupon === 1
                          ? "Visualizar Cupom"
                          : "Visualizar Voucher"}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <>
                  <button
                    style={{ color: configuracao?.background, gridGap: "8px" }}
                    className="saiba-mais"
                    onClick={(e) => {
                      handleSaibaMais(product.info);
                      e.stopPropagation();
                    }}
                  >                    
                    <ClickHandIcon color={configuracao?.background} />
                    Saiba Mais
                  </button>
                  <CheckBox
                    type="checkbox"
                    checked={check === product.id ? true : false}
                    id={product.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheck(product.id);
                      } else {
                        setCheck(0);
                      }
                    }}
                    color={configuracao?.background}
                  />
                </>
              )}
            </div>
          );
        })}

        <div className="btns">
          {showButton && (
            <Button
              title="Escolher Experiência"
              tipo="branco"
              onClick={escolhePremio}
            />
          )}
          <p
            className="depois"
            onClick={() => {
              setCheck(0);
              handleClose(false);
            }}
          >
            Voltar
          </p>
        </div>
      </div>
    </CodigoSms>
    <GenericModalBeforeReward
        isOpen={openGenericModalBeforeReward}
        clickContinue={() => {
          setOpenGenericModalBeforeReward(false);
          openResgate(dataGenericModalBeforeReward);
        }}
        color={cor}
        handleClose={() => setOpenGenericModalBeforeReward(false)}
        product={dataGenericModalBeforeReward}
      />
    </>
    
  );
}
